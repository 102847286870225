import React, { useState } from "react"

import { LocationSearch } from "src/components/common/googleMaps/locationSearch"

import { GoogleMapsTypes } from "src/components/common/googleMaps/types"
import { handleCoordinateChange } from "src/components/pages/services/flow/garages/helpers/handleCoordinateChange"
import { useTranslation } from "src/hooks/translation"

type GarageSearchDropdownProps = { defaultLocation?: string }

export const GarageSearchDropdown = ({
  defaultLocation,
}: GarageSearchDropdownProps) => {
  const [query, setQuery] = useState(defaultLocation || "")
  const { messages } = useTranslation()
  const translation = messages.components.cck.boldTeaser.garageSearchVariant

  const onCoordinateChange: GoogleMapsTypes["onCoordinateChange"] = async (
    newCoordinates,
    query,
  ) => {
    handleCoordinateChange({ coordinates: newCoordinates, query })
  }

  return (
    <LocationSearch
      onCoordinateChange={onCoordinateChange}
      query={query}
      setQuery={setQuery}
      placeholder={translation.locationPlaceholder}
    />
  )
}
