import {
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useState,
} from "react"

import { Icon, IconName } from "../icon"
import { Loader } from "../loader"
import { Info } from "src/components/common/info"
import { Tooltip, TooltipProps } from "src/components/common/tooltip"
import { create } from "src/helpers/bem"

import styles from "./Input.module.scss"
import { InputWrapper } from "./InputWrapper"
import { Autocomplete } from "./types/autocomplete"

const bem = create(styles, "Input")

export type InputLoadingProps = {
  ariaLabel: string
  className?: string
  disabled?: boolean
  icon: IconName
  onClick: () => void
}

export type InputButtonProps = {
  id: string
  ariaLabel?: string
  className?: string
  disabled?: boolean
  icon: IconName
  onClick: MouseEventHandler<HTMLButtonElement>
}

export type RightIcon = {
  id: string
  className?: string
  icon: IconName
}

export type InputProps = {
  id: string
  name: string
  autoComplete?: Autocomplete
  type?: "text" | "password" | "number" | "email" | "tel"
  error?: string
  extraErrorInformation?: ReactNode
  loading?: boolean
  rightButton?: InputButtonProps
  rightIcon?: RightIcon
  rightText?: string
  infoTooltip?: Pick<TooltipProps, "side" | "content">
  fieldClassName?: string
} & InputHTMLAttributes<HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      children,
      id,
      className,
      fieldClassName,
      autoComplete,
      value,
      error,
      extraErrorInformation,
      type = "text",
      disabled = false,
      loading,
      rightButton,
      rightIcon,
      rightText,
      infoTooltip,
      onBlur,
      onFocus,
      ...attributes
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false)
    return (
      <InputWrapper
        id={id}
        className={className}
        fieldClassName={fieldClassName}
        error={error}
        extraErrorInformation={extraErrorInformation}
        disabled={disabled}
        focused={focused}
        label={children}
      >
        <>
          <input
            className={bem("field", {
              "is-disabled": disabled,
              "has-error": !!error,
            })}
            id={id}
            ref={ref}
            value={value}
            type={type}
            disabled={disabled}
            autoComplete={autoComplete}
            aria-describedby={error ? `${id}-error` : undefined}
            onFocus={(e) => {
              setFocused(true)
              onFocus?.(e)
            }}
            onBlur={(e) => {
              setFocused(false)
              onBlur?.(e)
            }}
            data-valid={!error}
            {...attributes}
          />

          {rightButton && (
            <button
              type="button"
              id={rightButton.id}
              aria-label={rightButton.ariaLabel}
              className={bem("button", undefined, rightButton.className)}
              disabled={loading || rightButton.disabled}
              onMouseDown={(e) => {
                // Don't blur the input when this button is clicked.
                e.preventDefault()
              }}
              onClick={rightButton.onClick}
            >
              <Icon className={bem("icon")} name={rightButton.icon} />
            </button>
          )}

          {rightText && (
            <div className={bem("right-text-wrapper")}>
              <span className={bem("right-text-wrapper__text")}>
                {rightText}
              </span>
            </div>
          )}

          {!error && loading && (
            <span className={bem("loader")}>
              <Loader />
            </span>
          )}

          {infoTooltip && infoTooltip.content && (
            <div className={bem("info-tooltip")}>
              <Tooltip
                side={infoTooltip.side}
                trigger={<Info variant="secondary" />}
                content={infoTooltip.content}
              />
            </div>
          )}

          {rightIcon && (
            <div className={bem("right-icon", undefined, rightIcon.className)}>
              <Icon className={bem("icon")} name={rightIcon.icon} />
            </div>
          )}

          {error && (
            <div className={bem("error")}>
              <Icon className={bem("icon")} name="warningCircledFill" />
            </div>
          )}
        </>
      </InputWrapper>
    )
  },
)

Input.displayName = "Input"
