import { useRouter } from "next/router"
import React, { FC, useEffect, useState } from "react"

import { Button } from "src/components/common/button"
import { Form } from "src/components/common/form"

import { Modal, ModalContent } from "src/components/common/modal"
import { ServiceConfigurator } from "src/components/pages/services/service/ServiceConfigurator"
import { ServiceConfiguratorValidationContext } from "src/components/pages/services/service/ServiceConfiguratorValidationContext"
import { ROUTES, serviceFlowSteps, ServiceRouteName } from "src/config"

import { Category } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"
import { useTranslation } from "src/hooks/translation"

import { useABTestVariationValue } from "src/hooks/useABTestVariationValue"
import { useGetContentfulServicesData } from "src/hooks/useGetContentfulServicesData"
import { useServiceFlowData } from "src/states/serviceFlow"

import garageSearchActions from "src/states/serviceFlow/garageSearchActions"

import serviceCategoriesAction from "src/states/serviceFlow/serviceCategoriesAction"

import { ServiceCategory } from "src/states/serviceFlow/types"

import shoppingCartActions from "src/states/shoppingCart/shoppingCartActions"

import styles from "./BoldTeaserGarageSearch.module.scss"
import { GarageSearchDropdown } from "./GarageSearchDropdown"
import { ServiceSelectionDropdown } from "./ServiceSelectionDropdown"
import { BoldTeaserGarageSearchFormData } from "./types"

const bem = create(styles, "BoldTeaserGarageSearch")

type BoldTeaserGarageSearchProps = {
  defaultService?: ServiceRouteName
  defaultLocation?: string
}

export const BoldTeaserGarageSearch: FC<BoldTeaserGarageSearchProps> = ({
  defaultService,
  defaultLocation,
}) => {
  const [selectedService, setSelectedService] =
    useState<ServiceCategory | null>(null)
  const [selectedCategory, setSelectedcategory] = useState<Category | null>()
  const [openModal, setOpenModal] = useState(false)
  const router = useRouter()
  const pathArray = router.asPath?.split("/").filter(Boolean) || []
  const { categoriesData } = useGetContentfulServicesData()
  const { garageSearch } = useServiceFlowData()
  const isGarageSearchBoldTeaserVariant1 = useABTestVariationValue(
    "isGarageSearchBoldTeaserVariant1",
  )
  const isGarageSearchBoldTeaserVariant3 = useABTestVariationValue(
    "isGarageSearchBoldTeaserVariant3",
  )
  const { messages } = useTranslation()
  const translation = messages.components.cck.boldTeaser.garageSearchVariant

  const initialValues: BoldTeaserGarageSearchFormData = {
    service: defaultService || "",
  }

  useEffect(() => {
    if (defaultLocation) {
      garageSearchActions.clearGarageSearch()
    }

    // It should run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleButtonClick = (service: string) => {
    sendTrackingEvent.home({
      gaEvent: {
        action: "location_search_bold_teaser_click",
        value: {
          path: pathArray,
          service,
          location: garageSearch?.query,
        },
      },
    })
  }

  const onServiceSelected = (service: ServiceCategory | null) => {
    setSelectedService(service)
  }

  const onCategorySelected = (category: Category | null) => {
    setSelectedcategory(category)
  }

  const addServiceToCart = () => {
    if (selectedCategory) {
      setOpenModal(true)
    } else {
      serviceCategoriesAction.updateServiceCategoriesAndGarage(
        selectedService,
        false,
        false,
      )
      if (
        isGarageSearchBoldTeaserVariant1 ||
        isGarageSearchBoldTeaserVariant3
      ) {
        shoppingCartActions.open()
      } else {
        router.push(ROUTES.serviceFlow({ flowStep: serviceFlowSteps.garages }))
      }
    }
  }

  const onCategoryAddedToCart = () => {
    if (isGarageSearchBoldTeaserVariant3) {
      shoppingCartActions.open()
      setOpenModal(false)
    } else {
      router.push(ROUTES.serviceFlow({ flowStep: serviceFlowSteps.garages }))
    }
  }

  const renderLinkOrButton = (service: ServiceRouteName) => {
    if (!selectedService && !selectedCategory) {
      const link = !service
        ? ROUTES.serviceFlow({})
        : ROUTES.service({
            serviceName: service.split("-")[0] as ServiceRouteName,
          })
      return (
        <Button
          href={link}
          variant="primary"
          className={bem("button")}
          onClick={() => handleButtonClick(service)}
        >
          {translation.button.goToServiceDetails}
        </Button>
      )
    } else {
      return (
        <Button
          variant="primary"
          className={bem("button")}
          onClick={addServiceToCart}
        >
          {translation.button.goToGarage}
        </Button>
      )
    }
  }

  const onOpenChange = (open: boolean) => {
    setOpenModal(open)
  }

  return (
    <div>
      <Form<BoldTeaserGarageSearchFormData>
        initialValues={initialValues}
        onSubmit={() => {}} // No need to submit; the button is a link
        name="serviceSelectionDropdown"
        showSubmitButton={false}
      >
        {({ values: { service } }) => {
          return (
            <div className={bem()}>
              <div className={bem("inputs")}>
                <GarageSearchDropdown defaultLocation={defaultLocation} />
                <ServiceSelectionDropdown
                  categoriesData={categoriesData}
                  onServiceSelected={onServiceSelected}
                  onCategorySelected={onCategorySelected}
                />
              </div>
              {renderLinkOrButton(service as ServiceRouteName)}
            </div>
          )
        }}
      </Form>

      <Modal open={openModal} onOpenChange={onOpenChange}>
        <ModalContent
          variant="md"
          title={`${selectedCategory?.title} ${translation.configuration}`}
          bodyClassName={bem("content")}
        >
          {!!categoriesData?.length && selectedCategory && (
            <div className={bem("configurator")}>
              <ServiceConfiguratorValidationContext>
                <ServiceConfigurator
                  key={selectedCategory.internalServiceCategoryId}
                  categories={categoriesData}
                  category={selectedCategory}
                  onSuccess={onCategoryAddedToCart}
                  classNames={bem("configurator__body")}
                  location="boldTeaser"
                  onOpenChange={onOpenChange}
                />
              </ServiceConfiguratorValidationContext>
            </div>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}
