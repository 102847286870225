import { FC, PropsWithChildren, ReactNode } from "react"

import { create } from "src/helpers/bem"

import { InputError } from "./InputError"

import styles from "./InputWrapper.module.scss"

const bem = create(styles, "InputWrapper")

export type InputWrapperProps = PropsWithChildren<{
  id?: string
  className?: string
  fieldClassName?: string
  error?: string
  extraErrorInformation?: ReactNode
  disabled?: boolean
  focused?: boolean
  label?: ReactNode
}>

export const InputWrapper: FC<InputWrapperProps> = ({
  children,
  id,
  className,
  fieldClassName,
  error,
  disabled,
  focused,
  label,
  extraErrorInformation,
}) => {
  const modifiers = {
    "is-disabled": !!disabled,
    "is-focused": !!focused && !error,
    "has-error": !!error,
  }

  return (
    <div className={bem(undefined, undefined, className)} data-error={!!error}>
      {label && (
        <label className={bem("label", modifiers)} htmlFor={id}>
          {label}
        </label>
      )}

      <div className={bem("field-wrapper", modifiers, fieldClassName)}>
        {children}
      </div>
      {error && (
        <InputError id={`${id}-error`}>
          {error}
          {extraErrorInformation && extraErrorInformation}
        </InputError>
      )}
    </div>
  )
}
