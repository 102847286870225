import { GoogleMapsCoordinate } from "src/components/common/googleMaps/types"
import garageActions from "src/states/serviceFlow/garageActions"
import garageSearchActions from "src/states/serviceFlow/garageSearchActions"

type HandleCoordinateChangeProps = {
  coordinates: GoogleMapsCoordinate | undefined
  query?: string
}

export const handleCoordinateChange = ({
  coordinates,
  query,
}: HandleCoordinateChangeProps) => {
  garageSearchActions.setGarageSearchData({
    coordinates: {
      latitude: coordinates?.latitude,
      longitude: coordinates?.longitude,
    },
    query,
  })

  garageActions.clearSelectedGarage()
}
