import React, { forwardRef } from "react"

import { create } from "src/helpers/bem"

import styles from "./NativeSelect.module.scss"

const bem = create(styles, "NativeSelect")
type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>

export type NativeSelectOptions = Array<{
  label: string
  value: string
}>

export type NativeSelectGroupedOptions = {
  label: string
  options: NativeSelectOptions
}[]

type NativeSelectProps = {
  id: string
  disabled: boolean
  error?: string
  label?: string
  selected: string | undefined
  placeholderText: string
  isLoading: boolean
  loadingText: string
  onOptionSelect: (event: SelectChangeEvent) => void
} & (
  | { options: NativeSelectOptions; groupedOptions: null }
  | { groupedOptions: NativeSelectGroupedOptions; options: null }
)

const mapOptions = (option: NativeSelectOptions[0]) => (
  <option key={option.value} data-id={option.value} value={option.value}>
    {option.label}
  </option>
)

/**
 * This component should be used inside a Formik form
 */
export const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
  (
    {
      id,
      disabled,
      error,
      label,
      placeholderText,
      onOptionSelect,
      options,
      groupedOptions,
      selected,
      isLoading,
      loadingText,
    },
    ref,
  ) => {
    const modifiers = {
      "is-placeholder": !selected,
      "is-disabled": disabled,
      "has-error": !!error,
    }

    return (
      <div>
        {label && (
          <label className={bem("label", modifiers)} htmlFor={id}>
            {label}
          </label>
        )}
        <select
          className={bem("select", modifiers)}
          name={id}
          id={id}
          onChange={onOptionSelect}
          value={selected ?? ""}
          disabled={disabled}
          ref={ref}
        >
          <option value="" disabled>
            {isLoading ? loadingText : placeholderText}
          </option>
          {groupedOptions
            ? groupedOptions.map((group) => {
                const { label, options } = group
                return (
                  <optgroup
                    key={label}
                    label={label}
                    data-cy={`optgroup-${label}`}
                  >
                    {options.map(mapOptions)}
                  </optgroup>
                )
              })
            : options.map(mapOptions)}
        </select>
        {error && <span className={bem("error")}>{error}</span>}
      </div>
    )
  },
)

NativeSelect.displayName = "NativeSelect"
