import React, { FC } from "react"

import {
  ContentfulButton,
  ContentfulButtonProps,
} from "src/components/cck/elements/contentfulButton"
import { CopyText, CopyTextProps } from "src/components/cck/elements/copyText"
import { Headline, HeadlineProps } from "src/components/cck/elements/headline"
import {
  ContentfulImage,
  ContentfulImageProps,
} from "src/components/cck/elements/image"
import { ServiceRouteName } from "src/config"
import { screenBreakpoints } from "src/const/screenBreakpoints"

import { create } from "src/helpers/bem"
import {
  ImageSourceSizes,
  ImageType,
} from "src/helpers/contentful/createContentfulImageSources"
import { Media } from "src/helpers/media"

import {
  ContentfulSectionBoldTeaserPosition,
  ContentfulSectionBoldTeaserVariants,
} from "src/types/contentful/cck"

import { AccountBenefits } from "./accountBenefits/AccountBenefits"
import styles from "./BoldTeaser.module.scss"
import { BoldTeaserGarageSearch } from "./boldTeaserGarageSearch/BoldTeaserGarageSearch"

const bem = create(styles, "BoldTeaser")

export const imageSizes: ImageSourceSizes = [
  { maxScreenSize: 480, width: 480, height: 360, scales: [1, 2] },
  { maxScreenSize: 640, width: 640, height: 480, scales: [1, 2] },
  { maxScreenSize: 750, width: 750, height: 562, scales: [1, 2] },
  { maxScreenSize: 828, width: 414, height: 259, scales: [1, 2] },
  { maxScreenSize: 1080, width: 540, height: 338, scales: [1, 1.5] },
  { maxScreenSize: 1280, width: 640, height: 400, scales: [1, 1.5] },
  { maxScreenSize: 1440, width: 720, height: 450, scales: [1] },
  { maxScreenSize: 1920, width: 960, height: 600, scales: [1] },
]

export type BoldTeaserProps = {
  image: ContentfulImageProps
  headline: HeadlineProps
  copyText: CopyTextProps
  button?: ContentfulButtonProps
  variant?: ContentfulSectionBoldTeaserVariants
  imagePosition?: ContentfulSectionBoldTeaserPosition
  bottomAsterisk?: string
  preHeadline?: string
  /** Sets how the images should render. Only use "graph" for images without background */
  imageType?: ImageType
  /** In Garage Search type, select the default service */
  garageSearchDefaultService?: ServiceRouteName
  garageSearchDefaultLocation?: string
}

/**
 * The Bold Teaser is a full text-image teaser, which can be used for all high-priority topics
 *
 * There are multiple variants, each own with their specific settings. Try to not mix them up following this guide.
 */
export const BoldTeaser: FC<BoldTeaserProps> = ({
  image,
  headline,
  copyText,
  button,
  variant = "default",
  imagePosition = "right",
  imageType = "photo",
  preHeadline,
  bottomAsterisk,
  garageSearchDefaultService,
  garageSearchDefaultLocation,
}) => {
  const hasImage = !!image?.url

  if (!hasImage) {
    return null
  }

  const isHomePage = variant === "home-page"
  const isFullWidth = variant === "full-width"
  const isGarageSearch = variant === "garage-selection"
  const hasGradientAndCircle = variant === "gradient-and-circle"
  const hasNoBackground = variant === "no-background" || hasGradientAndCircle
  const hasGradientBackground =
    !isFullWidth && !hasNoBackground && !isGarageSearch && !isHomePage

  const modifiers = {
    [variant]: true,
    [`image-${imagePosition}`]: true,
    [imageType]: true,
  }

  if (variant === "account-benefits") {
    return (
      <AccountBenefits image={image} headline={headline} copyText={copyText} />
    )
  }

  return (
    <div className={bem(undefined, modifiers)}>
      {hasImage && (
        <div className={bem("image", modifiers)}>
          {isHomePage && <div className={bem("home-page-swoosh")} />}

          <ContentfulImage
            className={bem(undefined, modifiers)}
            {...image}
            imageSizes={imageSizes}
            imageType={imageType}
            loading={isFullWidth ? "eager" : "lazy"}
          />
          {hasGradientAndCircle && <div className={bem("circle")} />}
        </div>
      )}

      <div className={bem("content", modifiers)}>
        {hasGradientBackground && (
          <div className={bem("background-gradient", modifiers)} />
        )}

        <div className={bem("wrapper", modifiers)}>
          {preHeadline && <p className={bem("pre-headline")}>{preHeadline}</p>}

          <div className={bem("headline")}>
            <Headline {...headline} />
          </div>

          <div className={bem("copy-text")}>
            <CopyText {...copyText} />
          </div>

          {isGarageSearch && (
            <BoldTeaserGarageSearch
              defaultService={garageSearchDefaultService}
              defaultLocation={garageSearchDefaultLocation}
            />
          )}

          <Media maxWidth={screenBreakpoints.md}>
            {bottomAsterisk && (
              <p className={bem("bottom-asterisk", modifiers)}>
                * {bottomAsterisk}
              </p>
            )}
          </Media>

          {!!button?.link && (
            <div className={bem("button")}>
              <ContentfulButton {...button} />
            </div>
          )}

          <Media minWidth={screenBreakpoints.md}>
            {bottomAsterisk && (
              <p className={bem("bottom-asterisk", modifiers)}>
                * {bottomAsterisk}
              </p>
            )}
          </Media>
        </div>
      </div>
    </div>
  )
}
