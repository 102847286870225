import { useFormikContext } from "formik"
import React, { FC } from "react"

import {
  NativeSelect,
  NativeSelectOptions,
} from "src/components/common/form/nativeSelect"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { getServiceNameById } from "src/helpers/getServiceNameById"
import { useTranslation } from "src/hooks/translation"
import { useABTestVariationValue } from "src/hooks/useABTestVariationValue"

import { ServiceCategory } from "src/states/serviceFlow/types"

import { BoldTeaserGarageSearchFormData } from "./types"

type ServiceSelectionDropdownProps = {
  categoriesData?: Category[]
  onServiceSelected: (service: ServiceCategory | null) => void
  onCategorySelected: (category: Category | null) => void
}

const servicesCannotBeAddedToCart = [
  "AIR_CONDITIONING",
  "SHOCK_ABSORBER_CHANGE",
  "BRAKE_DISC_CHANGE",
  "BATTERY_CHANGE",
]
/**
 * Must be used inside a Formik form
 */
export const ServiceSelectionDropdown: FC<ServiceSelectionDropdownProps> = ({
  categoriesData,
  onServiceSelected,
  onCategorySelected,
}) => {
  const isGarageSearchBoldTeaserVariant1 = useABTestVariationValue(
    "isGarageSearchBoldTeaserVariant1",
  )
  const isGarageSearchBoldTeaserVariant2 = useABTestVariationValue(
    "isGarageSearchBoldTeaserVariant2",
  )
  const isGarageSearchBoldTeaserVariant3 = useABTestVariationValue(
    "isGarageSearchBoldTeaserVariant3",
  )
  const isGarageSearchBoldTeaserVariant4 = useABTestVariationValue(
    "isGarageSearchBoldTeaserVariant4",
  )
  const isABTestConfiguratorInModal =
    isGarageSearchBoldTeaserVariant3 || isGarageSearchBoldTeaserVariant4

  const isABTestVariant =
    isGarageSearchBoldTeaserVariant1 ||
    isGarageSearchBoldTeaserVariant2 ||
    isABTestConfiguratorInModal

  const { messages } = useTranslation()
  const translation = messages.components.cck.boldTeaser.garageSearchVariant

  const { setFieldValue, errors, values } =
    useFormikContext<BoldTeaserGarageSearchFormData>()

  const options: NativeSelectOptions =
    categoriesData
      ?.filter(
        (category) =>
          !!category.title &&
          !!category.internalServiceCategoryId &&
          // We have to filter "BRAKE_PAD_CHANGE" as it's treated as "BRAKE_DISC_CHANGE"
          category.internalServiceCategoryId !== "BRAKE_PAD_CHANGE",
      )
      .map((category) => {
        const serviceName = getServiceNameById(
          category.internalServiceCategoryId,
        )
        const value =
          isABTestVariant || isABTestConfiguratorInModal
            ? `${serviceName}-${category.internalServiceCategoryId}`
            : serviceName
        return {
          label: category.title || "",
          value,
        }
      }) || []

  if (isABTestVariant) {
    const wheelChangeIndex = options.findIndex((item) =>
      item.value.includes("WHEEL_CHANGE"),
    )
    options.splice(wheelChangeIndex + 1, 0, {
      label: "Radwechsel-Komplettpaket",
      value: `${getServiceNameById("WHEEL_CHANGE")}-WHEEL_CHANGE-WHEEL_CHANGE_TIRE_STORAGE_AND_BALANCING_COMBINATION`,
    })
    const huAuIndex = options.findIndex((item) => item.value.includes("HU_AU"))
    options.splice(huAuIndex + 1, 0, {
      label: "HU*/AU Paket",
      value: `${getServiceNameById("HU_AU")}-HU_AU-HU_AU_PRE_CHECK_COMBINATION`,
    })
  }

  function getServiceWithCategory(
    categoryId: string,
    serviceId: string,
  ): ServiceCategory | null {
    const categoryData = categoriesData?.filter(
      (category) => category.internalServiceCategoryId === categoryId,
    )[0]
    if (!categoryData) return null
    const service = categoryData.services.find((service) =>
      serviceId
        ? service.internalServiceId === serviceId
        : service.internalServiceId === categoryId,
    )
    const { services, ...otherCategoryData } = categoryData
    const serviceWithCategory = {
      ...otherCategoryData,
      additionalServices: [],
      service,
    }
    return serviceWithCategory
  }

  function getCategory(categoryId: string) {
    return categoriesData?.filter(
      (category) => category.internalServiceCategoryId === categoryId,
    )[0]
  }

  const onOptionSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const service = event.target.value
    if (isABTestVariant) {
      const categoryId = service.split("-")[1]
      const categoriesCannotBeAddedToCart =
        servicesCannotBeAddedToCart.includes(categoryId)

      if (isABTestConfiguratorInModal && categoriesCannotBeAddedToCart) {
        const category = await getCategory(categoryId)
        if (category) onCategorySelected(category)
      }

      if (!categoriesCannotBeAddedToCart) {
        const serviceId = service.split("-")[2]
        const serviceWithCategory = await getServiceWithCategory(
          categoryId,
          serviceId,
        )
        onServiceSelected(serviceWithCategory)
        onCategorySelected(null)
      } else {
        onServiceSelected(null)
      }
    } else {
      onServiceSelected(null)
    }

    await setFieldValue("service", service)
  }

  return (
    <NativeSelect
      id="service-selection-select"
      disabled={false}
      error={errors.service}
      onOptionSelect={onOptionSelect}
      placeholderText={translation.servicePlaceholder}
      options={options}
      groupedOptions={null}
      selected={values.service}
      isLoading={!categoriesData?.length}
      loadingText={translation.loading}
    />
  )
}
